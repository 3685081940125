import { apiName } from "@/constants";
import { setSnackBarOptions } from "@/redux/snackbar";
import { Dispatch } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import { NextRouter } from "next/router";
import { checkHrefIncludesKeywords } from "./utils";

// Helper function to reset all states
export const clearAllReduxStates = () => {
	localStorage.clear();
};

// Get central auth function return central auth url based on environment that could me same for local and staging, but different for preprod and production environment
export const getCentralAuthUrl = () => {
	if (
		window.location.href.includes("localhost") ||
		window.location.href.includes("staging")
	) {
		return `https://auth.staging.clinic.fertility.care`;
	} else if (window.location.href.includes("preproduction")) {
		return `https://auth.preproduction.clinic.fertility.care`;
	} else {
		return `https://auth.clinic.fertility.care`;
	}
};

// Function that navigate user to local view profile or eco system view profile
export const viewProfileRouteHandler = (router: NextRouter) => {
	const { href } = window.location;
	if (href.includes("localhost")) {
		router.push("/your-profile");
	} else {
		window.open(`${getCentralAuthUrl()}/profile/`, "_self");
	}
};

export const logout = async () => {
	await Auth.currentAuthenticatedUser()
		.then(async (currentUser) => {
			await API.post(apiName, `/api/websocket-logout`, {
				body: { user_id: currentUser.attributes.sub },
			})
				.then(async (data) => {
					await Auth.signOut({ global: true }).then(() => {
						// Clearing all the redux states
						clearAllReduxStates();
					});
				})
				.catch((error) => {
					console.log("Sign Out API error:", error);
					clearAllReduxStates();
				});
		})
		.catch((error) => {
			console.log("User unauth at logout:", error);
			clearAllReduxStates();
		});
};

// Function that navigate user to /login if local environment otherwise to eco system auth
export const logoutRouteHandler = (router: NextRouter, dispatch: Dispatch) => {
	const { href } = window.location;
	// Determine the redirection logic based on the environment
	if (href.includes("localhost")) {
		// Redirect to the local login for localhost
		router.push("/login");
	} else if (href.includes("staging") || href.includes("preproduction")) {
		dispatch(
			setSnackBarOptions({
				message: "",
				open: false,
				type: "success",
			}),
		);
		window.location.href = getCentralAuthUrl();
	} else {
		if (checkHrefIncludesKeywords(href)) {
			dispatch(
				setSnackBarOptions({
					message: "",
					open: false,
					type: "success",
				}),
			);
			window.location.href = getCentralAuthUrl();
		} else {
			// Redirect to the local login for localhost
			router.push("/login");
		}
	}
};
