import { CountryCode } from "libphonenumber-js";
import { getClinicDomain } from "./utils";

export const findClinicAllowedRegionCode = (
	countries: { id: number; name: string }[],
	id: string | null | undefined,
) => {
	const countryNameToCodeMap: { [key: string]: string } = {
		canada: "CA",
		"united states": "US",
		// Add more countries as needed
	};

	if (countries.length === 0 || !id) return "all";
	const matchedCountry = countries.find(
		(country) => country.id === Number(id),
	);
	// If a match is found, get the country code
	if (matchedCountry) {
		const countryCode =
			countryNameToCodeMap[matchedCountry.name.toLowerCase()];
		return countryCode ? [countryCode] : "all";
	}

	return "all";
};

export const languageLabels: { [key: string]: string } = {
	en: "English",
	fr: "French",
	es: "Spanish",
};

export const showDefaultClinicCountryCode = (): CountryCode => {
	const clinicDomain = getClinicDomain();
	const allowedDomains = ["illumepre", "illume", "illumetest"];
	if (allowedDomains.includes(clinicDomain)) {
		return "US";
	}
	return "CA";
};
